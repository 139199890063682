import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image-es5';
import { Link } from 'gatsby'
import CalendlyCTATwo from '@components/calendly/global-cta-two';

/**
 * Assets
 */
import './assets/styles/_index.scss';

const PostSidebar = (props) => {

    return (
        <div className={`post-sidebar ${props.AdditionalClass}`}>
            <img src={props.Image} className="post-sidebar__image static-contact-person-img" />
            <div className="post-sidebar__text">
                <h5>{props.Name}</h5>
                <h6>{props.Designation}</h6>
                <p><a href={`tel:+${props.Phone}`}>{props.Phone}</a></p>
            </div>
            <div className="post-sidebar__buttons">
                <Link to="/property-valuation" className="btn btn-primary">Valuation</Link>
                <CalendlyCTATwo url={props.Calendly} label="Video Call"/>
            </div>
        </div>
    )
}

export default PostSidebar;
