import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Carousel, Row, Col } from 'react-bootstrap';
import { useStaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image/withIEPolyfill";
import classNames from 'classnames';
import { isMobile, isIOS } from "react-device-detect";


/**
 * Assets
 */
import './assets/styles/_index.scss';
import Fullscreen from '@static/svg/enlarge.svg';

const slides = [
  {
    src: "slide.jpg",
    alt: "First slide",
  },
  {
    src: "slide.jpg",
    alt: "First slide",
  }
]

const PropertySlider = (props,{className}) => {
  const slider = useRef();
  const [currentSlide, setCurrentSlide] = useState(1);
  const [fullscreen, setFullscreen] = useState(false);
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  

  const openFullscreen = () => {
      setFullscreen(true);
    if (slider.current.requestFullscreen) {
      slider.current.requestFullscreen();
    } else if (slider.current.mozRequestFullScreen) {
      slider.current.mozRequestFullScreen();
    } else if (slider.current.webkitRequestFullscreen) {
      slider.current.webkitRequestFullscreen();
    } else if (slider.current.msRequestFullscreen) {
      slider.current.msRequestFullscreen();
    }
  }
  const closeFullscreen = () => {
      setFullscreen(false);
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  const toggleFullScreen = useCallback(() => {
    if(fullscreen) {
      closeFullscreen();
    } else {
      openFullscreen();
    }
  }, [fullscreen]);

  const handleEscape = useCallback((event) => {
    if((!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement)){
      setFullscreen(false);
    }
  }, [setFullscreen]);

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleEscape, false);
    document.addEventListener('mozfullscreenchange', handleEscape, false);
    document.addEventListener('MSFullscreenChange', handleEscape, false);
    document.addEventListener('webkitfullscreenchange', handleEscape, false);

    return () => {
      document.removeEventListener('fullscreenchange', handleEscape);
      document.removeEventListener('mozfullscreenchange', handleEscape);
      document.removeEventListener('MSFullscreenChange', handleEscape);
      document.removeEventListener('webkitfullscreenchange', handleEscape);
    };
  }, [handleEscape])


  const img = String(props.images)

  var json = [];

  var toSplit = img.split(",");
  for (var i = 0; i < toSplit.length; i++) {
      json.push({"images":toSplit[i]});
  }

  if(props.epc_images != ""){
  const epc = String(props.epc_images)


  var toSplitEpc = epc.split(",");
  for (var j = 0; j < toSplitEpc.length; j++) {
      json.push({"images":toSplitEpc[j]});
  }
  }



  return (
  <div className={classNames('slider-holder property-slider', className, {'is-fullscreen': fullscreen, 'is-iphone': isIOS && isMobile})} ref={slider}>
      <div className="slider-holder__inner">
        <Carousel
          onSlide={key => setCurrentSlide(key+1)}
          indicators={false}
        >

          {json.map(({images}, key) => {
            return (
            <Carousel.Item key={key}>
                <img 
                  className="d-block w-100 images"
                  src={images}
                />
              </Carousel.Item>
            )
          })}

        </Carousel>
        <div className="slide-controls">
          <Row>
            <Col>
              <div className="slide-progress">
                {currentSlide} / {json.length }
              </div>
            </Col>
            <Col md="auto">
              <div className="fullscreen" onClick={toggleFullScreen} role="presentation">
                <Fullscreen />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default PropertySlider
