import React from "react";
import ReactMarkdown from "react-markdown"

/**
 * Assets
 */
import './assets/styles/_index.scss';

/**
 * Components
 */
import PropertySlider from '@components/property-slider/property-slider';
import AccordionItem from '@components/accordion-item/accordion-item';
import Cta from '@components/cta/cta';
import Video from '@components/video/video';
import Relative from '@components/relative/relative';
import PostSidebar from "@components/post-sidebar/post-sidebar";


const Accordion = (props) => {
  return (
        <div className="accordions">
	            {props.items.map((items) => {
	            var title = items.Title
	              return (
	              <>
                    <AccordionItem item={{
                        title: title
                    }}>
                        <p><ReactMarkdown source={items.Content} /></p>
                    </AccordionItem>
	                </>
	              )
	            })}
                </div>
  );
}

export default Accordion;