import React from "react";
import Helmet from 'react-helmet';
import { Container } from 'react-bootstrap';
import ReactMarkdown from "react-markdown"

/**
 * Layouts
 */
import Layout from '@layouts/default';

/**
 * Components
 */
import Intro from '@components/intro/intro';
import About from '@components/about/about';
import SeamlessExperience from '@components/seamless-experience/seamless-experience';
import PropertyControl from '@components/property-control/property-control';
import Reviews from '@components/reviews/reviews';
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';
import PostContent from "@components/post-content/post-content";
import Accordion from "@components/post-content/accordion";
import PostSidebar from "@components/post-sidebar/post-sidebar";
import Slider from '@components/property-slider/gallery-slider';
import Cta from '@components/cta/cta';
import Video from '@components/video/video';
import Relative from '@components/relative/relative';
import TopBanner from '@components/top-banner/top-banner';
import { graphql } from 'gatsby'
import get from 'lodash/get'
import SEO from "../components/seo"
import Newsletter from '@components/book-video/newsletter';


const no_image = "/images/no-image.png"


class NewsEventsDetailsTemplate extends React.Component {

  render() {

    const Page = get(this, 'props.data.strapiNewsEvents')
    const Calendly = get(this, 'props.data.strapiGlobalConfig.Calendly_Link')

    if(Page.Contact_Person != null)
    {
      if(Page.Contact_Person.Image == null)
      {
        var imgURl = no_image
      }
      else
      {
        var imgURl = Page.Contact_Person.Image.publicURL 
      }
    }

    if((Page.Contact_Person.Calendly_Link == null) || (Page.Contact_Person.Calendly_Link == "")) {
      var video_call = Calendly
    }
    else {
      var video_call = Page.Contact_Person.Calendly_Link
    }



    return (
      <Layout>
        <Helmet 
          bodyAttributes={{
            class: 'static templates-news-and-events-template-js'
          }}
        />
        <SEO title={Page.Meta_Title} description={Page.Meta_Description} />
        <div className="page-body">

        {Page.Banner_Image != null &&
        <TopBanner Upload_Video={Page.Upload_Video} Banner_Image={Page.Banner_Image.childImageSharp.fluid} Embed_Video_URL={Page.Embed_Video_URL}/>
        }

        <Container>
          <div className="entry-heading">
            <h6>{Page.news_category.Category_Name}</h6>
            <h3>{Page.Title}</h3>
            <div className="post-meta">{Page.Date} / by <span className="news-author-name">{Page.Author}</span></div>
          </div>

          <div className="post-content">
              <div className="post-content__inner">
                  <div className="post-content__left">
                    {Page.Content.map((Content, i) => {
                    return (
                      <>
                      <PostContent Content={Content.Static_Content} CTA_Label={Content.CTA_Label} CTA_URL={Content.CTA_URL} />
                      {Content.Add_Accordion != null &&
                        <Accordion items={Content.Add_Accordion}/>
                      }
                      {Content.Choose_Images != null &&
                        <Slider slides={Content.Choose_Images}/>
                      }

                      {Content.Help_Title != null &&
                        <Cta link={`/${Content.Help_CTA_URL}`}  button={Content.Help_CTA_Label}>
                            <h5>{Content.Help_Title}</h5>
                            <p><ReactMarkdown source={Content.Help_Content} /></p>
                        </Cta>
                      }

                      {Content.Video_Background_Image != null && Content.Layout == "Default" &&
                        <Relative>
                            <Video 
                                height="500px" 
                                contain={true}
                                image={Content.Video_Background_Image.publicURL} 
                                video={Content.Upload_Video.publicURL} 
                            />
                        </Relative>
                      }
                      </>
                    )
                  })}
                  </div>
                  <div className="post-content__right">
                    {Page.Contact_Person != null &&
                      <PostSidebar Name={Page.Contact_Person.Name} Designation={Page.Contact_Person.Designation} Email={Page.Contact_Person.Email} Phone={Page.Contact_Person.Phone} Image={imgURl} Calendly={video_call}/>
                    }
                  </div>
              </div>
          </div>
        </Container>

        <Newsletter /> 

        
      <Breadcrumbs Parent_Label="About" Parent_URL="about/holroyds" Parent_1_Label="News, Events & Video" Parent_1_URL="../../about/latest-news" Page_1={Page.Title}/>
      </div>
      </Layout>
    )
  }
}

export default NewsEventsDetailsTemplate;


export const pageQuery = graphql`
  query NewsEventsDetailsTemplateSlug($slug: String!) {

    strapiNewsEvents(URL: { eq: $slug }) {
    Author
    Date(formatString: "Do MMMM YYYY")
    Meta_Description
    Meta_Title
    Title
    Banner_Image {
        childImageSharp {
          fluid(maxWidth: 1800, maxHeight: 820, cropFocus: CENTER, fit: INSIDE, background: "#fff", quality: 90, srcSetBreakpoints: [1800]) {
            ...GatsbyImageSharpFluid
          }
        }
    }
    Contact_Person {
      Calendly_Link
      Designation
      Email
      Image {
        publicURL
      }
      Name
      Phone
    }
    Content {
      CTA_Label
      CTA_URL
      Embed_Video_URL
      Help_CTA_Label
      Help_CTA_URL
      Help_Content
      Help_Title
      Layout
      Static_Content
      Add_Accordion {
        Content
        Title
      }
      Choose_Images {
        url
      }
      Upload_Video {
        publicURL
      }
      Video_Background_Image {
        publicURL
      }
    }
    news_category {
      Category_Name
    }
  }

  strapiGlobalConfig {
    Calendly_Link
  }


  }
`