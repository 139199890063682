import React from "react";
import { Link } from 'gatsby'
import ReactMarkdown from "react-markdown"
import CalendlyCTATwo from '@components/calendly/global-cta-two';

/**
 * Assets
 */
import './assets/styles/_index.scss';

/**
 * Components
 */
import PropertySlider from '@components/property-slider/property-slider';
import AccordionItem from '@components/accordion-item/accordion-item';
import Cta from '@components/cta/cta';
import Video from '@components/video/video';
import Relative from '@components/relative/relative';
import PostSidebar from "@components/post-sidebar/post-sidebar";


const PostContent = (props) => {
  return (
  <>
        <ReactMarkdown source={props.Content} />
        {props.CTA_URL != null && props.CTA_URL != "#calendly" &&
        	<Link to={`/${props.CTA_URL}`} className="btn btn-outline-primary">{props.CTA_Label}</Link>
        }
        {props.CTA_URL != null && props.CTA_URL == "#calendly" &&
        	<CalendlyCTATwo url={props.Calendly}/>
        }
  </>
  );
}

export default PostContent;