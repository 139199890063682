import React, { useState, Fragment } from 'react';
import { Accordion } from 'react-bootstrap';
import classNames from 'classnames';

const AccordionItem = ({item, children}) => {
  const [active, setActive] = useState(false);
  const handleClick = () => setActive(!active);
  return (
    <Fragment>
      <Accordion>
        <Accordion.Toggle variant="link" eventKey="1" className={classNames({'is-open': active})} onClick={handleClick}>
          {item.title}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          {item.content || children}
        </Accordion.Collapse>
      </Accordion>
    </Fragment>
  )
}

export default AccordionItem
