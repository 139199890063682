import React from "react";
import { Link } from "gatsby";

/**
 * Assets
 */
import './assets/styles/_index.scss';


const CallToAction = ({link, button, children}) => {
  return (
    <div className="call-to-action">
      {children}
      <Link to={link} className="primary-button btn btn-primary">{button}</Link>
    </div>
  );
}

export default CallToAction;